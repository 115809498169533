<template>
  <div class="flex flex-col items-center justify-center p-8 text-center">
    <div class="p-3 bg-primary-50 rounded-full">
      <div class="p-3 bg-primary-200 rounded-full">
        <div
          class="w-24 h-24 flex justify-center items-center bg-primary-400 rounded-full"
        >
          <Icon class="w-12 h-12 text-primary-50" :name="icon" />
        </div>
      </div>
    </div>
    <h4 class="mt-4 text-xl font-display md:text-2xl">
      {{ title }}
    </h4>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
  },
};
</script>
